<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="restaurant_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-filter></app-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="list"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import ButtonCreate from '../../components/shared/ButtonCreate'
import DataTable from '../../components/shared/DataTable'
import Filter from '../../components/filter/FilterRestaurant'

export default {
  name: 'RestaurantListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('restaurant.list.id'),
          title: this.$t('restaurant.list.title'),
          name: this.$t('restaurant.list.name')
        },
        actions: {
          copyToClipboard: 'id',
          detail: 'restaurant_detail',
          edit: 'restaurant_edit'
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    list () {
      return this.$store.getters['restaurant/list']
    },
    totalCount () {
      return this.$store.getters['restaurant/totalCount']
    },
    page () {
      return this.$store.getters['restaurant/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFilter: Filter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getList () {
      this.$store.dispatch('restaurant/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('restaurant/setPage', page)
      this.getList()
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
</style>
